import React from "react";

const TextDisplay = ({ text }) => {
  return (
    <div
      style={{
        fontSize: "2.3rem",
      }}
      className="mt-5 text-center"
    >
      {text}
    </div>
  );
};

export default TextDisplay;
