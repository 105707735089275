import React, { useReducer, useState, useEffect } from "react";
import DivisionForm from "../forms/division-form";
import OrganizationForm from "../forms/organization-form";
import { useSessionData } from "../../../hooks/useSession";
import SubmitModal from "../../atoms/modal";
import jwt_decode from "jwt-decode";

export const FormValuesContext = React.createContext({});
export const ErrorValuesContext = React.createContext({});

const formReducer = (state, action) => {
  switch (action.type) {
    case "NEXT":
      return { step: state.step + 1 };
    case "BACK":
      return { step: state.step - 1 };
    default:
      return state;
  }
};

const MultiStepForm = ({ jwtToken }) => {
  const [state, dispatch] = useReducer(formReducer, { step: 1 });


  const [formValues, setFormValues] = useState({
    companyName: "",
    companyAddress: "",
    pocName: "",
    pocEmail: "",
    pocPhone: "",
    pocTitle: "",
    spocName: "",
    spocEmail: "",
    spocPhone: "",
    spocTitle: "",
    naicsCode: "",
    careerSiteUrls: [""],
    hasMultipleFeins: false,
    remoteStates: [],
    feins: [{ id: 1, value: "", states: [] }],
  });

  const [errorValues, setErrorValues] = useState({
    pocPhoneError: "",
    spocPhoneError: "",
    pocEmailError: "",
    spocEmailError: "",
    feinErrors: [{ id: 1, value: "" }],
  });

  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (jwtToken) {
      const decodedJwt = jwt_decode(jwtToken);
      const decodedRef = decodedJwt.reference_number.trim()
      const decodedComp = decodedJwt.company_id.trim()

      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        reference_number: decodedRef,
        company_id: decodedComp,
      }));
    }
  }, [jwtToken]);

  useSessionData({
    setFormValues,
    jwtToken,
  });

  const handleStepChange = (step) => {
    if (step === 0) {
      if (state.step - 1 === 0) return;
      dispatch({ type: "BACK" });
      return;
    } else {
      if (state.step - 1 === 1) return;
      dispatch({ type: "NEXT" });
      return;
    }
  };

  const {
    companyName,
    companyAddress,
    reference_number,
    company_id,
    feins,
    pocName,
    pocTitle,
    pocPhone,
    pocEmail,
    spocName,
    spocTitle,
    naicsCode,
    spocPhone,
    spocEmail,
    careerSiteUrls,
    hasMultipleFeins,
    remoteStates,
  } = formValues;

  const handleSave = async (e) => {

    e.preventDefault();
    console.log('jwtToken', jwtToken)
    const payload = {
      companyName,
      companyAddress,
      reference_number,
      company_id,
      feins,
      pocName,
      pocTitle,
      pocPhone,
      pocEmail,
      spocName,
      spocTitle,
      naicsCode,
      spocPhone,
      spocEmail,
      careerSiteUrls,
      hasMultipleFeins,
      remoteStates,
    };


    const response = await fetch(`${process.env.REACT_APP_API_URL}/save`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    if (response.ok) {
      console.log("Data saved to DynamoDB.");
      setModalTitle("Form Saved");
      setModalMessage("Your session has been saved.");
      setShowModal(true);
    } else {
      console.error("Error saving data to DynamoDB.");
      setModalTitle("Error!");
      setModalMessage("There was an error saving the form.");
      setShowModal(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('jwtToken', jwtToken)

    console.log(formValues);
    const payload = {
      reference_number,
      company_id,
      feins,
      pocName,
      pocTitle,
      pocPhone,
      pocEmail,
      spocName,
      spocTitle,
      naicsCode,
      spocPhone,
      spocEmail,
      careerSiteUrls,
      hasMultipleFeins,
      remoteStates,
      companyName,
      companyAddress,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/items`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (response.ok) {
      console.log("Data saved to DynamoDB.");
      setModalTitle("Request Submitted!");
      setModalMessage(
        "Thank you for submitting your request. We have added it to our queue and will be processing it as soon as possible."
      );
      setShowModal(true);
    } else {
      console.error("Error saving data to DynamoDB.");
      setModalTitle("Error!");
      setModalMessage("There was an error submitting the form.");
      setShowModal(true);
    }
  };

  return (
    <>
      <div class="row justify-content-center mt-4">
        <div class="col-4">
          <div
            class={`nav-item ${state.step - 1 === 0 ? "selected" : ""}`}
            onClick={() => handleStepChange(0)}
          >
            <p style={{ color: state.step - 1 === 0 ? "#2f86dd" : "inherit" }}>
              Contact Information
            </p>
          </div>
          <hr
            style={
              state.step - 1 === 0
                ? { backgroundColor: "#2f86dd" }
                : { backgroundColor: "inherit" }
            }
          />
        </div>
        <div class="col-4">
          <div
            class={`nav-item ${state.step - 1 === 1 ? "selected" : ""}`}
            onClick={() => handleStepChange(1)}
          >
            <p style={{ color: state.step - 1 === 1 ? "#2f86dd" : "inherit" }}>
              Organization
            </p>
          </div>
          <hr
            style={
              state.step - 1 === 1
                ? { backgroundColor: "#2f86dd" }
                : { backgroundColor: "inherit" }
            }
          />
        </div>
      </div>

      {/* <Nav
        items={["Contact", "Organization"]}
        selectedForm={state.step - 1}
        onItemClick={handleStepChange}
      /> */}
      <FormValuesContext.Provider value={{ formValues, setFormValues }}>
        <ErrorValuesContext.Provider value={{ errorValues, setErrorValues }}>
          <div className="d-flex justify-content-center">
            <CurrentStep
              dispatch={dispatch}
              step={state.step}
              handleSave={handleSave}
              handleSubmit={handleSubmit}
            />
            <SubmitModal
              show={showModal}
              onHide={() => setShowModal(false)}
              message={modalMessage}
              title={modalTitle}
            />
          </div>
        </ErrorValuesContext.Provider>
      </FormValuesContext.Provider>
    </>
  );
};

const CurrentStep = ({ dispatch, step, handleSave, handleSubmit }) => {
  switch (step) {
    case 1:
      return <OrganizationForm dispatch={dispatch} handleSave={handleSave} />;
    case 2:
      return (
        <DivisionForm
          dispatch={dispatch}
          handleSave={handleSave}
          handleSubmit={handleSubmit}
        />
      );
    default:
      return <div>Error: Unknown step</div>;
  }
};

export default MultiStepForm;
