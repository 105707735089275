import { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

function SubmitModal(props) {
  useEffect(() => {
    if (props.onHide) {
      const closeModal = setTimeout(() => {
        props.onHide();
      }, 4000);

      return () => {
        clearTimeout(closeModal);
      };
    }
  }, [props]);

  return (
    <Modal isOpen={props.show}>
      <ModalHeader className="ml-2">{props.title}</ModalHeader>
      <ModalBody className="ml-2">{props.message && props.message}</ModalBody>
      <ModalFooter>
        <button className="btn btn-outline text-primary" onClick={props.onHide}>
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
}

export default SubmitModal;
