function evaluateFields(state) {
    console.log('state', state);
    const { information, hasAccount } = state;

    if (!information) return 0;

    if (hasAccount) {
        if (information.username && information.password &&
            information.securityQuestion && information.securityAnswer) {
            return 1; // All fields are complete
        }
    } else {
        if (information.streetAddress && information.city &&
            information.zip && information.taxID) {
            if (information.employeePassword && information.employeeUsername &&
                information.employeeSecurityQuestion && information.employeeSecurityAnswer) {
                return 1; // All fields are complete
            } else {
                return 2; // Third state
            }
        }
    }

    return 0; // Fields are incomplete
}

export { evaluateFields };