import React from "react";
import Select from "react-select";
import { Tooltip, Input, Textarea } from "@jobtarget/ui-library";
import { evaluateFields } from "../../../../../util/states.util";


function MultipleFeinNumbers({
  formValues,
  setFormValues,
  handleAccountAnswer,
  handleAccountInputChange,
  handleAccountPasteChange,
  stateOptions,
  handleAddFein,
  errorValues,
  setErrorValues,
}) {
  function getRemoteStatesForFein(feinIndex) {
    const targetFein = formValues.feins[feinIndex];

    if (!targetFein) {
      return [];
    }

    return targetFein.states.map((state) => {
      return {
        value: state.value,
        label: state.label,
      };
    });
  }


  const handleFeinChange = (e, index) => {
    const isValid = /^[0-9]+$/.test(e.target.value);

    const newFeins = [...formValues.feins];
    newFeins[index].value = e.target.value;
    setFormValues({
      ...formValues,
      feins: newFeins,
    });

    const newFeinErrors = [...errorValues.feinErrors];



    newFeinErrors[index].value = (isValid || e.target.value === "" ? "" : "Only numeric characters can be entered.");
    setErrorValues({
      ...errorValues,
      feinErrors: newFeinErrors,
    });
  }

  const handleFeinPasteChange = (e, index) => {
    const isValid = /^[0-9]+$/.test(e);

    const newFeins = [...formValues.feins];
    newFeins[index].value = e;
    setFormValues({
      ...formValues,
      feins: newFeins,
    });

    const newFeinErrors = [...errorValues.feinErrors];

    newFeinErrors[index].value = (isValid || e === "" ? "" : "Only numeric characters can be entered.");
    setErrorValues({
      ...errorValues,
      feinErrors: newFeinErrors,
    });
  }

  return (
    <>
      {formValues.hasMultipleFeins &&
        formValues.feins.map((fein, index) => (
          <>
            <hr />
            <div className="form-group d-flex" key={fein.id}>
              <div className="col">
                <h5>FEIN # {fein.id}</h5>
              </div>
              <div className="col">
                <label htmlFor={`fein-${fein.id}`}>FEIN # {fein.id}</label>
                <Input
                  type="text"
                  className={`form-control ${errorValues.feinErrors[index]?.value === "" ? "" : "border-danger"}`}
                  id={`fein-${fein.id}`}
                  name={`fein-${fein.id}`}
                  value={fein.value}
                  onChange={(e) => { handleFeinChange(e, index) }}
                  onPasteChange={(e) => { handleFeinPasteChange(e, index) }}
                />
                <div className="text-danger" style={{ fontSize: "80%" }}> {errorValues.feinErrors[index]?.value} </div>
                <div className="form-group mt-3">
                  <label htmlFor={`businessName-${fein.id}`}>
                    Operating Business Name
                  </label>
                  <Input
                    className="form-control"
                    type="text"
                    id={`businessName-${fein.id}`}
                    name={`businessName-${fein.id}`}
                    value={fein.businessName}
                    sanitize
                    onChange={(e) => {
                      const newFeins = [...formValues.feins];
                      newFeins[index].businessName = e.target.value;
                      setFormValues({
                        ...formValues,
                        feins: newFeins,
                      });
                    }}
                    onPasteChange={(e) => {
                      const newFeins = [...formValues.feins];
                      newFeins[index].businessName = e;
                      setFormValues({
                        ...formValues,
                        feins: newFeins,
                      });
                    }
                    }
                  />
                </div>
                <div className="form-group mt-3">
                  <label>States</label>
                  <Select
                    className=" mb-2"
                    options={stateOptions}
                    isMulti={true}
                    value={formValues.feins[index].states}
                    onChange={(selectedStates) => {
                      const newFeins = [...formValues.feins];
                      newFeins[index].states = selectedStates;
                      setFormValues({
                        ...formValues,
                        feins: newFeins,
                      });
                    }}
                  />
                  <small>
                    The states selected here will determine which state job
                    boardaccounts are set up for this FEIN. Please be sure to
                    select allapplicable states if this FEIN has locations in
                    multiple states.
                  </small>
                </div>
                <div className="form-group mt-3">
                  <label className="d-block mt-3" htmlFor={`remote-states`}>
                    Remote Jobs
                  </label>

                  <small className="mb-3">
                    Please select a default state where your remote postings
                    should go. Many companies select their headquarters
                    location, but any state where you have a physical location
                    will work.
                  </small>

                  <Select
                    className="mt-3"
                    options={getRemoteStatesForFein(index)}
                    isMulti={true}
                    value={formValues.feins[index].remoteStates}
                    onChange={(selectedStates) => {
                      const newFeins = [...formValues.feins];
                      newFeins[index].remoteStates = selectedStates;
                      setFormValues({
                        ...formValues,
                        feins: newFeins,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <hr />

            {fein.states.map((stateObj) => (
              <div key={`${fein.id}-${stateObj.value}`}>
                <div className="d-flex my-4">
                  <h5 className="col ">
                    State Job Board:
                    <span className="d-block font-weight-bold mt-2">
                      {stateObj.label}
                      {(() => {
                        switch (evaluateFields(stateObj)) {
                          case 1:
                            return <i style={{ marginLeft: "0.5rem" }} className="fal fa-check-circle text-success"></i>;
                          case 2:
                            return <i style={{ marginLeft: "0.5rem" }} className="fal fa-question-circle text-warning"></i>; // Icon for third state
                          default:
                            return <i style={{ marginLeft: "0.5rem" }} className="fal fa-exclamation-circle text-danger"></i>;
                        }
                      })()}
                    </span>
                  </h5>
                  <div className="col">
                    <p>
                      Do you already have an account with this state job board?
                    </p>
                    <div className="form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`yes-${fein.id}-${stateObj.value}`}
                        name={`account-${fein.id}-${stateObj.value}`}
                        value="yes"
                        onChange={() =>
                          handleAccountAnswer(fein.id, stateObj.value, "yes")
                        }
                        checked={stateObj.hasAccount === true ? true : false}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`yes-${fein.id}-${stateObj.value}`}
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        id={`no-${fein.id}-${stateObj.value}`}
                        name={`account-${fein.id}-${stateObj.value}`}
                        value="no"
                        onChange={() =>
                          handleAccountAnswer(fein.id, stateObj.value, "no")
                        }
                        checked={stateObj.hasAccount === false ? true : false}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`no-${fein.id}-${stateObj.value}`}
                      >
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {stateObj.showFields && (
                  <div>
                    {stateObj.hasAccount ? (
                      <>
                        <div className="row mt-4">
                          <div className="col-md-6 offset-md-6 mb-3">
                            <label
                              htmlFor={`${fein.id}-${stateObj.value}-username`}
                              className="form-label"
                            >
                              Username
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id={`${fein.id}-${stateObj.value}-username`}
                              value={
                                formValues.feins
                                  .find((f) => f.id === fein.id)
                                  .states.find(
                                    (s) => s.value === stateObj.value
                                  ).information?.username || ""
                              }
                              sanitize
                              onChange={(e) =>
                                handleAccountInputChange(
                                  fein.id,
                                  stateObj.value,
                                  "username",
                                  e.target.value
                                )
                              }
                              onPasteChange={(e) => {
                                handleAccountPasteChange(
                                  fein.id,
                                  stateObj.value,
                                  "username",
                                  e
                                )
                              }}
                            />
                          </div>
                          <div className="col-md-6 offset-md-6 mb-3">
                            <label
                              htmlFor={`${fein.id}-${stateObj.value}-password`}
                              className="form-label"
                            >
                              Password
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id={`${fein.id}-${stateObj.value}-password`}
                              value={
                                formValues.feins
                                  .find((f) => f.id === fein.id)
                                  .states.find(
                                    (s) => s.value === stateObj.value
                                  ).information?.password || ""
                              }
                              onChange={(e) =>
                                handleAccountInputChange(
                                  fein.id,
                                  stateObj.value,
                                  "password",
                                  e.target.value
                                )
                              }
                              onPasteChange={(e) => {
                                handleAccountPasteChange(
                                  fein.id,
                                  stateObj.value,
                                  "password",
                                  e
                                )
                              }
                              }
                            />
                          </div>
                          <div className="col-md-6 offset-md-6 mb-3">
                            <label
                              htmlFor={`${fein.id}-${stateObj.value}-security-question`}
                              className="form-label"
                            >
                              Security Question
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id={`${fein.id}-${stateObj.value}-security-question`}
                              value={
                                formValues.feins
                                  .find((f) => f.id === fein.id)
                                  .states.find(
                                    (s) => s.value === stateObj.value
                                  ).information?.securityQuestion || ""
                              }
                              sanitize
                              onChange={(e) =>
                                handleAccountInputChange(
                                  fein.id,
                                  stateObj.value,
                                  "securityQuestion",
                                  e.target.value
                                )
                              }
                              onPasteChange={(e) => {
                                handleAccountPasteChange(
                                  fein.id,
                                  stateObj.value,
                                  "securityQuestion",
                                  e
                                )
                              }
                              }
                            />
                          </div>
                          <div className="col-md-6 offset-md-6 mb-3">
                            <label
                              htmlFor={`${fein.id}-${stateObj.value}-security-answer`}
                              className="form-label"
                            >
                              Security Answer
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id={`${fein.id}-${stateObj.value}-security-answer`}
                              value={
                                formValues.feins
                                  .find((f) => f.id === fein.id)
                                  .states.find(
                                    (s) => s.value === stateObj.value
                                  ).information?.securityAnswer || ""
                              }
                              sanitize
                              onChange={(e) =>
                                handleAccountInputChange(
                                  fein.id,
                                  stateObj.value,
                                  "securityAnswer",
                                  e.target.value
                                )
                              }
                              onPasteChange={(e) => {
                                handleAccountPasteChange(
                                  fein.id,
                                  stateObj.value,
                                  "securityAnswer",
                                  e
                                )
                              }
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="col-md-6 offset-md-6 mt-4 mb-3">
                          <label
                            htmlFor={`${fein.id}-${stateObj.value}-street-address`}
                            className="form-label"
                          >
                            Physical Street Address
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id={`${fein.id}-${stateObj.value}-street-address`}
                            value={
                              formValues.feins
                                .find((f) => f.id === fein.id)
                                .states.find((s) => s.value === stateObj.value)
                                .information?.streetAddress || ""
                            }
                            sanitize
                            onChange={(e) =>
                              handleAccountInputChange(
                                fein.id,
                                stateObj.value,
                                "streetAddress",
                                e.target.value
                              )
                            }
                            onPasteChange={(e) => {
                              handleAccountPasteChange(
                                fein.id,
                                stateObj.value,
                                "streetAddress",
                                e
                              )
                            }
                            }
                          />
                        </div>
                        <div className="col-md-6 offset-md-6 mb-3">
                          <label
                            htmlFor={`${fein.id}-${stateObj.value}-city`}
                            className="form-label"
                          >
                            City
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id={`${fein.id}-${stateObj.value}-city`}
                            value={
                              formValues.feins
                                .find((f) => f.id === fein.id)
                                .states.find((s) => s.value === stateObj.value)
                                .information?.city || ""
                            }
                            sanitize
                            onChange={(e) =>
                              handleAccountInputChange(
                                fein.id,
                                stateObj.value,
                                "city",
                                e.target.value
                              )
                            }
                            onPasteChange={(e) => {
                              handleAccountPasteChange(
                                fein.id,
                                stateObj.value,
                                "city",
                                e
                              )
                            }
                            }
                          />
                        </div>
                        <div className="col-md-6 offset-md-6 mb-3">
                          <label
                            htmlFor={`${fein.id}-${stateObj.value}-zip`}
                            className="form-label"
                          >
                            Zip
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id={`${fein.id}-${stateObj.value}-zip`}
                            value={
                              formValues.feins
                                .find((f) => f.id === fein.id)
                                .states.find((s) => s.value === stateObj.value)
                                .information?.zip || ""
                            }
                            sanitize
                            onChange={(e) =>
                              handleAccountInputChange(
                                fein.id,
                                stateObj.value,
                                "zip",
                                e.target.value
                              )
                            }
                            onPasteChange={(e) => {
                              handleAccountPasteChange(
                                fein.id,
                                stateObj.value,
                                "zip",
                                e
                              )
                            }
                            }
                          />
                        </div>
                        <div className="col-md-6 offset-md-6 mb-3">
                          <label
                            htmlFor={`${fein.id}-${stateObj.value}-tax-id`}
                            className="form-label"
                          >
                            <Tooltip
                              maxWidth={400}
                              position="top"
                              message="The SUID number is the State Unemployment Tax ID Number. This number is also known as the State Unemployment Insurance Number, State Unemployment Insurance Tax Number, State Unemployment Tax Number, or State Unemployment Insurance Tax ID Number."
                            >
                              SUID Number
                            </Tooltip>
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id={`${fein.id}-${stateObj.value}-tax-id`}
                            value={
                              formValues.feins
                                .find((f) => f.id === fein.id)
                                .states.find((s) => s.value === stateObj.value)
                                .information?.taxID || ""
                            }
                            sanitize
                            onChange={(e) =>
                              handleAccountInputChange(
                                fein.id,
                                stateObj.value,
                                "taxID",
                                e.target.value
                              )
                            }
                            onPasteChange={(e) => {
                              handleAccountPasteChange(
                                fein.id,
                                stateObj.value,
                                "taxID",
                                e
                              )
                            }
                            }
                          />
                        </div>
                        <div className="col-md-6 offset-md-6 mb-3">
                          <h4 className="mt-4 mb-3">
                            To be filled by JobTarget employee
                          </h4>
                        </div>

                        <div className="col-md-6 offset-md-6 mb-3">
                          <label
                            htmlFor={`${fein.id}-${stateObj.value}-employee-username`}
                            className="form-label"
                          >
                            Username
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id={`${fein.id}-${stateObj.value}-employee-username`}
                            value={
                              formValues.feins
                                .find((f) => f.id === fein.id)
                                .states.find((s) => s.value === stateObj.value)
                                .information?.employeeUsername || ""
                            }
                            sanitize
                            onChange={(e) =>
                              handleAccountInputChange(
                                fein.id,
                                stateObj.value,
                                "employeeUsername",
                                e.target.value
                              )
                            }
                            onPasteChange={(e) => {
                              handleAccountPasteChange(
                                fein.id,
                                stateObj.value,
                                "employeeUsername",
                                e
                              )
                            }
                            }
                          />
                        </div>
                        <div className="col-md-6 offset-md-6 mb-3">
                          <label
                            htmlFor={`${fein.id}-${stateObj.value}-employee-password`}
                            className="form-label"
                          >
                            Password
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id={`${fein.id}-${stateObj.value}-employee-password`}
                            value={
                              formValues.feins
                                .find((f) => f.id === fein.id)
                                .states.find((s) => s.value === stateObj.value)
                                .information?.employeePassword || ""
                            }
                            onChange={(e) =>
                              handleAccountInputChange(
                                fein.id,
                                stateObj.value,
                                "employeePassword",
                                e.target.value
                              )
                            }
                            onPasteChange={(e) => {
                              handleAccountPasteChange(
                                fein.id,
                                stateObj.value,
                                "employeePassword",
                                e
                              )
                            }
                            }
                          />
                        </div>

                        <div className="col-md-6 offset-md-6 mb-3">
                          <label
                            htmlFor={`${fein.id}-${stateObj.value}-employee-security-question`}
                            className="form-label"
                          >
                            Security Question
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id={`${fein.id}-${stateObj.value}-employee-security-question`}
                            value={
                              formValues.feins
                                .find((f) => f.id === fein.id)
                                .states.find((s) => s.value === stateObj.value)
                                .information?.employeeSecurityQuestion || ""
                            }
                            sanitize
                            onChange={(e) =>
                              handleAccountInputChange(
                                fein.id,
                                stateObj.value,
                                "employeeSecurityQuestion",
                                e.target.value
                              )
                            }
                            onPasteChange={(e) => {
                              handleAccountPasteChange(
                                fein.id,
                                stateObj.value,
                                "employeeSecurityQuestion",
                                e
                              )
                            }
                            }
                          />
                        </div>
                        <div className="col-md-6 offset-md-6 mb-3">
                          <label
                            htmlFor={`${fein.id}-${stateObj.value}-employee-security-answer`}
                            className="form-label"
                          >
                            Security Answer
                          </label>
                          <Input
                            type="text"
                            className="form-control"
                            id={`${fein.id}-${stateObj.value}-employee-security-answer`}
                            value={
                              formValues.feins
                                .find((f) => f.id === fein.id)
                                .states.find((s) => s.value === stateObj.value)
                                .information?.employeeSecurityAnswer || ""
                            }
                            sanitize
                            onChange={(e) =>
                              handleAccountInputChange(
                                fein.id,
                                stateObj.value,
                                "employeeSecurityAnswer",
                                e.target.value
                              )
                            }
                            onPasteChange={(e) => {
                              handleAccountPasteChange(
                                fein.id,
                                stateObj.value,
                                "employeeSecurityAnswer",
                                e
                              )
                            }
                            }
                          />
                        </div>
                        <div className="col-md-6 offset-md-6 mb-3">
                          <label
                            htmlFor={`${fein.id}-${stateObj.value}-employee-registration-notes`}
                            className="form-label"
                          >
                            Registration Notes
                          </label>
                          <Textarea
                            type="text"
                            className="form-control"
                            id={`${formValues.feins[0].id}-${stateObj.value}-employee-registration-notes`}
                            value={
                              formValues.feins
                                .find((f) => f.id === fein.id)
                                .states.find((s) => s.value === stateObj.value)
                                .information?.employeeRegistrationNotes || ""
                            }
                            sanitize
                            onChange={(e) =>
                              handleAccountInputChange(
                                fein.id,
                                stateObj.value,
                                "employeeRegistrationNotes",
                                e.target.value
                              )
                            }
                            onPasteChange={(e) => {
                              handleAccountPasteChange(
                                fein.id,
                                stateObj.value,
                                "employeeRegistrationNotes",
                                e
                              )
                            }
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            ))}
            {formValues.hasMultipleFeins && (
              <div className="row my-4">
                <div className="col-md-6 ">
                  <h4>FEIN and Organization Details</h4>
                  <small>
                    Enter detals for another organization with your FEIN number.
                  </small>
                </div>
                <div className="col-md-6 ">
                  <button
                    className="btn btn-outline border-primary text-primary"
                    type="button"
                    onClick={handleAddFein}
                  >
                    + Add Another FEIN
                  </button>
                </div>
              </div>
            )}
          </>
        ))}
    </>
  );
}

export default MultipleFeinNumbers;
