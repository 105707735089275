import React, { useContext } from "react";
import { FormValuesContext } from "../../multistep-form/index";
import { ErrorValuesContext } from "../../multistep-form/index";
import { Input } from "@jobtarget/ui-library";

const OrganizationForm = ({ dispatch, handleSave }) => {
  const { formValues, setFormValues } = useContext(FormValuesContext);
  const { errorValues, setErrorValues } = useContext(ErrorValuesContext);



  const handleEmailChange = (e) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value);
    switch (e.target.id) {
      case "poc-email":
        setFormValues({
          ...formValues,
          pocEmail: e.target.value,
        });
        setErrorValues({
          ...errorValues,
          pocEmailError:
            isValid || e.target.value === ""
              ? ""
              : "A valid email address is required.",
        });
        break;
      case "spoc-email":
        setFormValues({
          ...formValues,
          spocEmail: e.target.value,
        });
        setErrorValues({
          ...errorValues,
          spocEmailError:
            isValid || e.target.value === ""
              ? ""
              : "A valid email address is required.",
        });
        break;
      default:
        break;
    }
  };

  const handlePhoneChange = (e) => {
    const isValid = /^[0-9()+-]+$/.test(e.target.value);
    switch (e.target.id) {
      case "poc-phone":
        setFormValues({
          ...formValues,
          pocPhone: e.target.value,
        });
        setErrorValues({
          ...errorValues,
          pocPhoneError:
            isValid || e.target.value === ""
              ? ""
              : "Only numeric characters can be entered.",
        });
        break;
      case "spoc-phone":
        setFormValues({
          ...formValues,
          spocPhone: e.target.value,
        });
        setErrorValues({
          ...errorValues,
          spocPhoneError:
            isValid || e.target.value === ""
              ? ""
              : "Only numeric characters can be entered.",
        });
        break;
      default:
        break;
    }
  };


  const handlePaste = (id, value) => {
    switch (id) {
      case "poc-phone":
      case "spoc-phone":
        if (id === "poc-phone") { id = "pocPhone" }
        if (id === "spoc-phone") { id = "spocPhone" }
        const isPhoneValid = /^[0-9()+-]+$/.test(value);
        const phoneError = isPhoneValid || value === "" ? "" : "Only numeric characters can be entered.";

        setFormValues(prev => ({
          ...prev,
          [id]: value
        }));


        setErrorValues(prev => ({ ...prev, [`${id}Error`]: phoneError }));
        break;

      case "poc-email":
      case "spoc-email":
        if (id === "poc-email") { id = "pocEmail" }
        if (id === "spoc-email") { id = "spocEmail" }
        const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        const emailError = isEmailValid || value === "" ? "" : "A valid email address is required.";

        setFormValues(prev => ({ ...prev, [id]: value }));
        setErrorValues(prev => ({ ...prev, [`${id}Error`]: emailError }));
        break;

      default:
        break;
    }
  };

  const isNextDisabled =
    errorValues.pocPhoneError !== "" ||
    errorValues.spocPhoneError !== "" ||
    errorValues.pocEmailError !== "" ||
    errorValues.spocEmailError !== "";

  return (
    <div className="col-8 p-0">
      <form className="mb-4">
        <fieldset>
          {/* <hr /> */}
          <div className="form-group mt-3">
            <h4 htmlFor="company-name" className="mb-2 d-block">
              Company Name
            </h4>
            <Input
              data-toggle="tooltip"
              title="Enter the name of the company"
              type="text"
              id="company-name"
              name="company-name"
              className="form-control mt-3"
              value={formValues.companyName}
              sanitize
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  companyName: e.target.value,
                })
              }
              onPasteChange={val => setFormValues({
                ...formValues,
                companyName: val
              })}
            />
          </div>
          <div className="form-group mt-4">
            <h4 htmlFor="company-name" className="my-2 d-block">
              Company Address
            </h4>
            <Input
              data-toggle="tooltip"
              title="Enter the address of the company"
              type="text"
              id="company-address"
              name="company-address"
              className="form-control mt-3"
              value={formValues.companyAddress}
              sanitize
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  companyAddress: e.target.value,
                })
              }
              onPasteChange={val => setFormValues({
                ...formValues,
                companyAddress: val
              })}
            />
          </div>
        </fieldset>

        <fieldset>
          <h4 className="my-3">Contact Information</h4>
          <small>
            State job boards require a point of contact from the company, and
            may occasionally reach out for account activation, password issues,
            or questions about job postings. JobTarget will also be listed as a
            contact and will work directly with the state job board whenever
            possible to resolve questions. Please list one or two points of
            contact to be listed with the state job board accounts.
          </small>
        </fieldset>

        <fieldset className="d-flex mt-4">
          <div className="col p-0">
            <h5 className="d-block mt-3 ">
              Primary Point of Contact for Implementation
            </h5>
          </div>
          <div className="col">
            <div className="form-group mt-3">
              <label
                htmlFor="poc-name"
                className="mb-2"
                data-toggle="tooltip"
                title="Enter the name of the primary point of contact"
              >
                Name
              </label>
              <Input
                type="text"
                id="poc-name"
                name="poc-name"
                className="form-control"
                value={formValues.pocName}
                sanitize
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    pocName: e.target.value,
                  })
                }
                onPasteChange={val => setFormValues({
                  ...formValues,
                  pocName: val
                }
                )}
              />
            </div>
            <div className="form-group">
              <label htmlFor="poc-title" className="mb-2">
                Title
              </label>
              <Input
                type="text"
                id="poc-title"
                name="poc-title"
                className="form-control"
                value={formValues.pocTitle}
                sanitize
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    pocTitle: e.target.value,
                  })
                }
                onPasteChange={val => setFormValues({
                  ...formValues,
                  pocTitle: val
                }
                )}
              />
            </div>
            <div className="form-group">
              <label for="poc-phone" className="mb-2">
                Phone
              </label>
              <Input
                type="text"
                id="poc-phone"
                name="poc-phone"
                className={`form-control ${errorValues.pocPhoneError === "" ? "" : "border-danger"
                  }`}
                value={formValues.pocPhone}
                onChange={(e) => handlePhoneChange(e)}
                onPasteChange={(val) => handlePaste("poc-phone", val)}
              />
              <div className="text-danger" style={{ fontSize: "80%" }}>
                {" "}
                {errorValues.pocPhoneError}{" "}
              </div>
            </div>
            <div className="form-group">
              <label for="poc-email" className="mb-2">
                Email
              </label>
              <Input
                type="text"
                id="poc-email"
                name="poc-email"
                className={`form-control ${errorValues.pocEmailError === "" ? "" : "border-danger"
                  }`}
                value={formValues.pocEmail}
                onChange={(e) => handleEmailChange(e)}
                onPasteChange={(val) => handlePaste("poc-email", val)}
              />
              <div className="text-danger" style={{ fontSize: "80%" }}>
                {" "}
                {errorValues.pocEmailError}{" "}
              </div>
            </div>
          </div>
        </fieldset>

        <hr />

        <fieldset className="d-flex mt-4">
          <div className="col p-0">
            <h5 className="d-block">
              Secondary Point of Contact for Implementation
            </h5>
          </div>
          <div className="col">
            <div className="form-group">
              <label for="spoc-name" className="mb-2">
                Name
              </label>
              <Input
                type="text"
                id="spoc-name"
                name="spoc-name"
                className="form-control"
                value={formValues.spocName}
                sanitize
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    spocName: e.target.value,
                  })
                }
                onPasteChange={val => setFormValues({
                  ...formValues,
                  spocName: val
                }
                )}
              />
            </div>
            <div className="form-group">
              <label for="spoc-title" className="mb-2">
                Title
              </label>
              <Input
                type="text"
                id="spoc-title"
                name="spoc-title"
                className="form-control"
                value={formValues.spocTitle}
                sanitize
                onChange={(e) =>
                  setFormValues({
                    ...formValues,
                    spocTitle: e.target.value,
                  })
                }
                onPasteChange={val => setFormValues({
                  ...formValues,
                  spocTitle: val
                }
                )}
              />
            </div>
            <div className="form-group">
              <label for="spoc-phone" className="mb-2">
                Phone
              </label>
              <Input
                type="text"
                id="spoc-phone"
                name="spoc-phone"
                className={`form-control ${errorValues.spocPhoneError === "" ? "" : "border-danger"
                  }`}
                value={formValues.spocPhone}
                onChange={(e) => handlePhoneChange(e)}
                onPasteChange={(val) => handlePaste("spoc-phone", val)}
              />
              <div className="text-danger" style={{ fontSize: "80%" }}>
                {" "}
                {errorValues.spocPhoneError}{" "}
              </div>
            </div>
            <div className="form-group">
              <label for="spoc-email" className="mb-2">
                Email
              </label>
              <Input
                type="text"
                id="spoc-email"
                name="spoc-email"
                className={`form-control ${errorValues.spocEmailError === "" ? "" : "border-danger"
                  }`}
                value={formValues.spocEmail}
                onChange={(e) => handleEmailChange(e)}
                onPasteChange={(val) => handlePaste("spoc-email", val)}
              />
              <div className="text-danger" style={{ fontSize: "80%" }}>
                {" "}
                {errorValues.spocEmailError}{" "}
              </div>
            </div>
          </div>
        </fieldset>

        <hr />

        <fieldset className="d-flex">
          <div className="col p-0">
            <h5 className="d-block ">Career Site URL for jobs</h5>
            <small className="mb-3">
              Please list the career site URL to be used for setting up the job
              feed from your company to JobTarget. This will also be used as the
              application URL on the state job boards.
            </small>
          </div>
          <div className="col">
            <div className="form-group ">
              {formValues.careerSiteUrls.map((url, index) => (
                <div key={index} className="mt-4">
                  <label htmlFor={`career-site-url-${index}`} className="mb-2">
                    URL {index + 1}
                  </label>
                  <Input
                    type="text"
                    id={`career-site-url-${index}`}
                    name={`career-site-url-${index}`}
                    className="form-control"
                    value={url}
                    sanitize
                    onChange={(e) => {
                      const updatedUrls = [...formValues.careerSiteUrls];
                      updatedUrls[index] = e.target.value;
                      setFormValues({
                        ...formValues,
                        careerSiteUrls: updatedUrls,
                      });
                    }}
                    onPasteChange={(val) => {
                      const updatedUrls = [...formValues.careerSiteUrls];
                      updatedUrls[index] = val;
                      setFormValues({
                        ...formValues,
                        careerSiteUrls: updatedUrls,
                      });
                    }}
                  />
                </div>
              ))}
            </div>
            <button
              type="button"
              className="btn btn-outline border-primary text-primary"
              onClick={() =>
                setFormValues({
                  ...formValues,
                  careerSiteUrls: [...formValues.careerSiteUrls, ""],
                })
              }
            >
              + Add Another URL
            </button>
          </div>
        </fieldset>
      </form>
      <hr />

      <div className="d-flex align-items-center">
        <div className="col-5 d-flex justify-content-start p-0">
          <p>
            For detailed information about the implementation process, as well
            as a step by step guide to this form, click <br />
            <a
              className="text-primary "
              href="/onboard.pdf"
              download="OFCCP Implementation Guide.pdf"
            >
              download Implementation Guide
            </a>
          </p>
        </div>
        <div className="col-7 d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-lg btn-outline boder border-primary text-primary my-3 mr-2"
            onClick={(e) => handleSave(e)}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-lg btn-primary my-3"
            onClick={() => {
              dispatch({ type: "NEXT" });
            }}
            disabled={isNextDisabled}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrganizationForm;
