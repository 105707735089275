import React, { useContext, useEffect } from "react";
import { states } from "../../../../data/states";
import { FormValuesContext, ErrorValuesContext } from "../../multistep-form/index";
import MultipleFeinNumbers from "./fein-choice/multiple";
import NoMultipleFeinNumbers from "./fein-choice/not-multiple";
import { Tooltip, Input } from "@jobtarget/ui-library";

const DivisionForm = ({ dispatch, handleSave, handleSubmit }) => {
  const { formValues, setFormValues } = useContext(FormValuesContext);
  const { errorValues, setErrorValues } = useContext(ErrorValuesContext);

  useEffect(() => {
    if (formValues.feins.length > errorValues.feinErrors.length) {
      setErrorValues({
        ...errorValues,
        feinErrors: [
          ...errorValues.feinErrors,
          { id: errorValues.feinErrors.length + 1, value: "" },
        ],
      });
    }
  }, [formValues.feins, errorValues.feinErrors, setErrorValues, errorValues]);

  // Update formValues object every time user selects a different option
  const handleRadioButtonChange = (event) => {
    console.log("handleRadioButtonChange", event.target.value);
    const value = event.target.value === "yes";
    setFormValues((prevValues) => ({ ...prevValues, hasMultipleFeins: value }));
  };
  const handleAccountAnswer = (feinId, stateValue, answer) => {
    setFormValues({
      ...formValues,
      feins: formValues.feins.map((fein) =>
        fein.id === feinId
          ? {
            ...fein,
            states: fein.states.map((state) =>
              state.value === stateValue
                ? {
                  ...state,
                  hasAccount: answer === "yes",
                  showFields: true,
                }
                : state
            ),
          }
          : fein
      ),
    });
  };

  const handleAccountInputChange = (feinId, stateValue, field, value) => {

    // Get a copy of the current formValues object
    const updatedFormValues = { ...formValues };

    // Find the corresponding fein object
    const fein = updatedFormValues.feins.find((f) => f.id === feinId);

    // Find the corresponding state object within the fein object
    const state = fein.states.find((s) => s.value === stateValue);

    // Update the information property of the state object with the new value
    state.information = {
      ...state.information,
      [field]: value,
    };

    // Set the updated formValues state
    setFormValues(updatedFormValues);

  };


  const handleAccountPasteChange = (feinId, stateValue, field, value) => {
    // Get a copy of the current formValues object
    const updatedFormValues = { ...formValues };

    // Find the corresponding fein object
    const fein = updatedFormValues.feins.find((f) => f.id === feinId);

    // Find the corresponding state object within the fein object
    const state = fein.states.find((s) => s.value === stateValue);

    // Update the information property of the state object with the new value
    state.information = {
      ...state.information,
      [field]: value,
    };

    // Set the updated formValues state
    setFormValues(updatedFormValues);
  }

  const handleAddFein = () => {
    setFormValues({
      ...formValues,
      feins: [
        ...formValues.feins,
        { id: formValues.feins.length + 1, value: "", states: [] },
      ],
    });
    setErrorValues({
      ...errorValues,
      feinErrors: [
        ...errorValues.feinErrors,
        { id: errorValues.feinErrors.length + 1, value: "" },
      ],
    });
  };

  const stateOptions = states.map((state) => ({
    value: state.key,
    label: state.value,
  }));

  const isSubmitDisabled = (
    errorValues.pocPhoneError !== "" ||
    errorValues.spocPhoneError !== "" ||
    errorValues.pocEmailError !== "" ||
    errorValues.spocEmailError !== "" ||
    errorValues.feinErrors.some(feinErrors => feinErrors.value !== "")
  );

  return (
    <div className="col-8 p-0">
      <form className="mt-3">
        <fieldset>
          <h4 className="my-3">Organization</h4>
          <small>
            On this page, we will collect the information necessary to set up
            your organization with the appropriate state job boards. If needed,
            you may complete this form with the information you have readily
            available, and return to fill in the rest
          </small>
        </fieldset>
        <fieldset>
          {/* <hr /> */}
          <div className="form-group mt-4">
            <h4 htmlFor="company-name" className="mb-2 d-block">
              NAICS Code
            </h4>
            <Input
              data-toggle="tooltip"
              title="Enter the NAICS code for your company"
              type="text"
              id="naicsCode"
              name="naicsCode"
              className="form-control mt-3"
              value={formValues.naicsCode}
              sanitize
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  naicsCode: e.target.value,
                })
              }
              onPasteChange={(e) => {
                console.log(e)
                setFormValues({
                  ...formValues,
                  naicsCode: e
                })
              }}
            />
          </div>
        </fieldset>

        <fieldset className="d-flex mt-4">
          <div className="col p-0 mb-4">
            <h4 htmlFor="multipleFeinNumbers">Do you have multiple FEINs?</h4>
            <span className="text-primary">
              <Tooltip
                maxWidth={400}
                position="top"
                message="If your company has multiple Federal Employment Identification Numbers (FEINs), each FEIN will need a unique account for each state where you have a physical location within that FEIN. JobTarget will also need a way to associate each of your job postings with the appropriate FEIN number."
              >
                What's this for?
              </Tooltip>
            </span>
          </div>

          <div className="col form-check-inline">
            <div className="ml-4">
              <input
                type="radio"
                id="yes"
                name="multipleFeinNumbers"
                value="yes"
                onChange={handleRadioButtonChange}
                className="mr-2 form-check-input"
                checked={formValues.hasMultipleFeins}
              />
              <label htmlFor="yes">Yes</label>
            </div>
            <div className="ml-3">
              <label class="form-check-label">
                <input
                  type="radio"
                  id="no"
                  name="multipleFeinNumbers"
                  value="no"
                  onChange={handleRadioButtonChange}
                  className="mr-2 form-check-input"
                  checked={!formValues.hasMultipleFeins}
                />
              </label>
              <label htmlFor="no">No</label>
            </div>
          </div>
        </fieldset>
        <MultipleFeinNumbers
          formValues={formValues}
          setFormValues={setFormValues}
          handleAccountAnswer={handleAccountAnswer}
          handleAccountInputChange={handleAccountInputChange}
          handleAccountPasteChange={handleAccountPasteChange}
          stateOptions={stateOptions}
          handleAddFein={handleAddFein}
          errorValues={errorValues}
          setErrorValues={setErrorValues}
        />
        <NoMultipleFeinNumbers
          formValues={formValues}
          setFormValues={setFormValues}
          handleAccountAnswer={handleAccountAnswer}
          handleAccountInputChange={handleAccountInputChange}
          handleAccountPasteChange={handleAccountPasteChange}
          stateOptions={stateOptions}
          errorValues={errorValues}
          setErrorValues={setErrorValues}
        />
        <hr />
      </form>
      <div className="d-flex mt-4">
        <div className="col-5 d-flex justify-content-start d-flex p-0">
          <p>
            For detailed information about the implementation process, as well
            as a step by step guide to this form, click <br />
            <a
              className="text-primary "
              href="/onboard.pdf"
              download="OFCCP Implementation Guide.pdf"
            >
              download Implementation Guide
            </a>
          </p>
        </div>
        <div className="col-7 d-flex justify-content-end align-items-center">
          <button
            className="btn btn-lg text-primary mr-3"
            onClick={() => {
              dispatch({ type: "BACK" });
            }}
          >
            Back
          </button>
          <button
            className="btn btn-lg border-primary text-primary mr-3"
            onClick={(e) => handleSave(e)}
          >
            Save
          </button>

          <button
            className="btn btn-lg btn-primary"
            onClick={(e) => {
              handleSubmit(e);
            }}
            disabled={isSubmitDisabled}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DivisionForm;
