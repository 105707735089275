import React, { useEffect } from "react"; // import useState hook from react
import TextDisplay from "./components/atoms/title"; // import text display component
import MultiStepForm from "./components/molecules/multistep-form"; // import multi step form component
import "./styles/global.css"; // import global css
import { datadogRum } from "@datadog/browser-rum";
import { Spinner } from "@jobtarget/ui-library";

datadogRum.init({
  applicationId: process.env.REACT_APP_applicationId || "",
  clientToken: process.env.REACT_APP_clientToken || "",
  site: "datadoghq.com",
  service: "ciw",
  env: process.env.REACT_APP_env,
  // Specify a version number to identify the deployed version of your application in Datadog
  version: process.env.REACT_APP_DD_VERSION || 'local',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();

// Link the user information in DataDog to the logged in user
window.dispatchEvent(new CustomEvent("requestuserinfo:jt:navbar"));
window.addEventListener("userinfo:jt:navbar", event => {
  datadogRum.setUser({
    id: event.detail.user_id,
    name: event.detail.company.companyName
  })
});

function App() {
  const [jwtToken, setJwtToken] = React.useState(null);

  // Effect to set jwtToken from URL
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const jwtTokenParam = urlParams.get("jwtToken");
    if (jwtTokenParam) {
      setJwtToken(jwtTokenParam);
    }
  }, []);


  useEffect(() => {
    console.log("ciw-workbook v1.0.19")
  }, []);


  return (
    <>
      <div className="row mt-2">
        <div className="col-12">
          <TextDisplay text="Online Implementation Workbook" />
        </div>
      </div>{
        jwtToken ? <MultiStepForm jwtToken={jwtToken} /> : <Spinner />
      }
    </>
  );
}

export default App;
