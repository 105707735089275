import { useEffect } from "react";

function useSessionData({ setFormValues, jwtToken }) {
  useEffect(() => {
    const fetchSessionData = () => {


      if (jwtToken) {
        fetch(`${process.env.REACT_APP_API_URL}/get-session`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.status === 400 || res.status === 500) {
              window.location.href = "http://www.jobtarget.com";
            }
            return res.json();
          })
          .then((data) => {
            if (data.error) {
              window.location.href = "http://www.jobtarget.com";
            } else {
              setFormValues((prevState) => ({
                ...prevState,
                ...data,
              }));
            }
          })
          .catch((error) => console.error(error));
      } else {
        window.location.href = "http://www.jobtarget.com";
      }
    };

    const delay = 500; // set the delay to 500 milliseconds or adjust as needed
    const timeoutId = setTimeout(fetchSessionData, delay);

    return () => clearTimeout(timeoutId);
  }, [setFormValues, jwtToken]);
}

export { useSessionData };
